import React, { Component, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios'

import './About.css'
import { variables } from '../../Variables';
import QuestionSection from '../../components/questionSection';


const About = () => {
    const [selectedId, setSelectedId] = useState(1)
    const [aboutGambar, setAboutGambar] = useState('Prop_Tech_2.png')
    const [aboutText, setAboutText] = useState('<p><strong>vCloudProperty+</strong>&nbsp;adalah suatu solusi berbasis cloud yang komprehensif dan terintegrasi yang dikembangkan oleh PT. Virtual Properti Plus, yang ditujukan untuk berbagai jenis property baik itu perumahan, perkantoran, shopping mall, industrial estate, mixed used development, dll. Solusi yang ditawakan oleh vCloudProperty+ mencakup seluruh proses bisnis yang berhubungan dengan properti, termasuk property management, real estate management, building management, procurement management, financial accounting management. Modul-modul dan fitur yang ada pada vCloudProperty+ dikembangkan berdasarkan standard bisnis terbaik dalam industri properti.</p><p>Berikut ini adalah beberapa keunggulan yang akan anda dapatkan dengan menggunakan vCloudProperty+ :</p><ol><li>vCloudProperty+ sudah dirancang sesuai dengan standar bisnis terbaik dan dapat dikonfigurasi berdasarkan kebutuhan klien, selain itu vCloudProperty+ juga sudah dirancang sesuai dengan regulasi / peraturan pemerintah Indonesia.</li><li>vCloudProperty+ dapat digunakan tanpa adanya batasan pengguna / user dalam suatu perusahaan.</li><li>Layanan dukungan dengan tim support dedicated untuk para klien.</li><li>Dengan menggunakan teknologi berbasis cloud maka vCloudProperty+ dapat diakses melalui berbagai macam perangkat (notebook, smartphone, tablet, dll).</li></ol>')

    useEffect(() => {
        // fetch(variables.API_URL+'about')
        // .then(response=>response.json())
        // .then(data=> {
        //     console.log('data', data)
        //     setSelectedId(data[0].id)
        //     setAboutGambar(data[0].gambar)
        //     setAboutText(data[0].text_about)
        // })
        
    }, [])
    return (
        <div className='about'>
            <div className='about-header'>
                <div className='header-bg-about container-fluid' style={{ backgroundImage: `url(${window.location.origin}/gambar_header/image.png)` }}>
                    <h1 className='header-title'>About Us</h1>
                </div>
            </div>
            <div className='about-content'>
                <div className='container container-fluid'>
                    <div className='about-content-desc'>
                        {selectedId && aboutGambar && aboutText
                            ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5}>
                                        {/* <img className='img-fluid' width={420} src={variables.PHOTO_URL+'About/'+aboutGambar} /> */}
                                        <img className='img-fluid' width={420} src={'/gambar_header/'+aboutGambar} />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <div className='about-content-desc-text'>
                                            <div dangerouslySetInnerHTML={{__html: aboutText}} />
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                            : ''
                        }
                    </div>
                </div>
            </div>
            <div className='about-content-contact'>
                <QuestionSection />
                <div className='sp-curved-1'><div className='sp-curved-2'></div></div>
            </div>
        </div>
    )
}
export default About;