import React, { Component, useEffect, useState } from 'react';
import axios from 'axios'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import './css/ourClient.css'
import { variables } from '../Variables';

import logoVpp from '../assets/logo_new.png'

const ourClient = (props) => {
    let idxClient = 0
    const filterClient = props && props.filterClient
    const filtered = props && props.filtered
    const matches = props && props.matches
    const logoClientData = props.logoClientData && props.logoClientData
    const dataClientTemp = props.dataClientTemp && props.dataClientTemp
    const kategoriClientData = props.kategoriClientData && props.kategoriClientData
    const manyClientKategoriData = props.manyClientKategoriData && props.manyClientKategoriData

    const handleChange = (event, newAlignment) => {
        props.setAlignment(newAlignment);
    };

    return (
        <div className='our-client container container-fluid'>
            <div className='toggle-filter'>
                <ToggleButtonGroup
                    color="primary"
                    value={props.alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    orientation={(matches) ? 'horizontal' : 'vertical'}
                >
                    <ToggleButton className={'toggle-filter-all ' + (filtered ? '' : 'Mui-selected')} onClick={()=>{props.setFilterClient(0)}} value="All">
                        ALL
                    </ToggleButton>
                    {kategoriClientData && kategoriClientData.map((data, idx) => {
                        return (
                            <ToggleButton
                                className='toggle-button-client'
                                value={data.id}
                                onClick={()=>{
                                    props.setFilterClient(data.id)
                                    props.setFiltered(true)
                                }}
                            >
                                {data.kategori}
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <div className='our-client-sidebar'>
                        <h2 className='our-client-sidebar-title'>
                            Our Client
                        </h2>
                        <p className='our-client-sidebar-desc'>
                            We had the pleasure to work with our clients
                        </p>
                        <Button variant="outlined" className='our-client-sidebar-button' href='/client'>More Client</Button>
                    </div>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Grid container spacing={2}>

                        {
                        (logoClientData && filtered && filterClient)
                            ? (
                                logoClientData.map((client)=>{
                                    return dataClientTemp.map((dataClient)=>{
                                        if (client.id === dataClient && idxClient < 18) {
                                            idxClient = idxClient + 1
                                            return(
                                                <Grid item xs={6} sm={4} lg={2} className='grid-item-client'>
                                                    <Card sx={{ maxWidth: 345 }}>
                                                        <img
                                                        src={'/gambar_client/'+client.gambar}
                                                        alt="Client Logo"
                                                        className='client-logo img-fluid'
                                                        />
                                                    </Card>
                                                </Grid>
                                            )
                                        }
                                    })
                                })
                            )
                            : logoClientData && logoClientData.slice(0,18).map((client, idx) => {

                                return (
                                    <Grid item xs={6} sm={4} lg={2} className='grid-item-client'>
                                        <Card sx={{ maxWidth: 345 }}>
                                            <img
                                            src={'/gambar_client/'+client.gambar}
                                            alt="Client Logo"
                                            className='client-logo img-fluid'
                                            />
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}
export default ourClient;