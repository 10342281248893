import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp'

// Layout
import Navbar from './components/navbar'
import Footer from './components/footer'
import SidebarAdmin from './components/sidebarAdmin'

// Content User Page
import Homepage from './pages/homepage/Homepage'
import Solution from './pages/solution-page/Solution'
import Partnership from './pages/partnership-page/Partnership'
import Price from './pages/price-page/Price'
import PrivateCloud from './pages/privateCloud-page/PrivateCloud'
import About from './pages/about-page/About'
import Login from './pages/login-page/Login';
import Client from './pages/client-page/Client';
import P404 from './pages/404/P404';

// Content Admin Page
import HomeAdmin from './pages/admin/home-admin-page/HomeAdmin';
import ImagesAdmin from './pages/admin/images-admin-page/ImagesAdmin'
import ButtomAdmin from './pages/admin/buttom-admin-page/ButtomAdmin'
import AboutAdmin from './pages/admin/about-admin-page/AboutAdmin'
import PartnershipAdmin from './pages/admin/partnership-admin-page/PartnershipAdmin'
import ProductAdmin from './pages/admin/product-admin-page/ProductAdmin'
import MessageAdmin from './pages/admin/message-admin-page/MessageAdmin'
import FaqAdmin from './pages/admin/faq-admin-page/FaqAdmin'
import CategoryClientAdmin from './pages/admin/categoryClient-admin-page/CategoryClientAdmin'
import LogoClientAdmin from './pages/admin/logoClient-admin-page/LogoClientAdmin';
import KontakAdmin from './pages/admin/kontak-admin-page/KontakAdmin'
import MediasosialAdmin from './pages/admin/mediaSosial-admin-page/MediasosialAdmin'
import UsersAdmin from './pages/admin/users-admin-page/UsersAdmin'
import PrivateAdmin from './pages/admin/private-admin-page/PrivateAdmin';


import './App.css';
import { variables } from './Variables';


function App() {
  const [pathName, setPathName] = useState('')
  const [hehe, setHehe] = useState(true)
  const [auth, setAuth] = useState({})
  const [authUserId, setAuthUserId] = useState({})
  const [fetchFiturInformation, setFetchFiturInformation] = useState([])
  const [fetchManyUserFiturInformation, setFetchManyUserFiturInformation] = useState([])
  const [accessiblePages, setAccessiblePages] = useState([])

  // useEffect(() => {
  //   setPathName(window.location.pathname)
  //   const data = JSON.parse(window.localStorage.getItem('APP_AUTH'))
  //   if ( data !== null ) {
  //     setAuth(data)
  //     setAuthUserId(data.dataAuth.id)
  //   }
    
  //   if (window.location.pathname.includes('/admin/')) {
  //     fetch(variables.API_URL+'fitur')
  //     .then(response=>response.json())
  //     .then(data=> {
  //         setFetchFiturInformation(data)
  //         getListuserFitur()
  //     })
  //   }
      
  // }, [])

  // useEffect(() => {
  //   validateUser()
  // }, [fetchManyUserFiturInformation])
  
  // const getListuserFitur = () => {
  //   fetch(variables.API_URL+'manyuserfitur')
  //   .then(response=>response.json())
  //   .then(data=> {
  //     setFetchManyUserFiturInformation(data)
  //   })
  // }
  
  // const validateUser = () => {
  //   let fiturData = fetchFiturInformation && fetchFiturInformation
  //   let manyUserFiturData = fetchManyUserFiturInformation && fetchManyUserFiturInformation
  //   let accessPages = []
    
  //   manyUserFiturData.map((muf)=>{
  //     fiturData.map((fitur)=>{
  //       if (
  //         muf.user_id === authUserId &&
  //         muf.fitur_id === fitur.id
  //         ) {
  //           accessPages = [...accessPages, fitur.fitur]
  //           setAccessiblePages(accessPages)
  //         }
  //       })
  //     })
  //   }
    
    return (
    <Router>
      {pathName.includes('/admin') === true ? '' : <Navbar />}
      <div className="App">
        <Routes>
          <Route path='*' element={<P404 />} />
          <Route path='/' element={<Homepage />} />
          <Route path='/client' element={<Client />} />
          <Route path='/solution/:id' element={<Solution />} />
          <Route path='/blog' element={''} />
          <Route path='/partnership' element={<Partnership />} />
          <Route path='/price' element={<Price />} />
          <Route path='/private-cloud' element={<PrivateCloud />} />
          <Route path='/contact-us' element={''} />
          <Route path='/about-us' element={<About />} />
          {/* <Route path='/admin' element={<Login />} /> */}
          {/* {auth 
            ? (
              <>
                <Route path='/admin/home' element={<HomeAdmin />} />
                <Route 
                  path='/admin/images' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Header'))
                      ? <ImagesAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/buttom' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Header'))
                      ? <ButtomAdmin />
                      : <PrivateAdmin />
                  }  
                />
                <Route 
                  path='/admin/about' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Header'))
                      ? <AboutAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/partnership' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Header'))
                      ? <PartnershipAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/product'
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Content'))
                      ? <ProductAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/message' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Content'))
                      ? <MessageAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/faq' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Content'))
                      ? <FaqAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/kategoriclient' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Client'))
                      ? <CategoryClientAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/client' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Client'))
                      ? <LogoClientAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/kontak' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Footer'))
                      ? <KontakAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/mediasosial'
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Footer'))
                      ? <MediasosialAdmin />
                      : <PrivateAdmin />
                  } 
                />
                <Route 
                  path='/admin/users' 
                  element={
                    (accessiblePages && accessiblePages.find((ap)=> ap==='Management User'))
                      ? <UsersAdmin />
                      : <PrivateAdmin />
                  } 
                />
              </>
            )
            : ''
          } */}
        </Routes>
      </div>
      {pathName.includes('/admin') === true ? '' : <Footer />}
      {/* <Footer /> */}
      {pathName.includes('/admin') === true 
        ? '' 
        : <FloatingWhatsApp
        phoneNumber="6287776868717"
        accountName="Virtual Properti Plus"
        allowEsc
        statusMessage='Software Property Management System #1 di Indonesia'
        avatar={`${window.location.origin}/assets/img/logo_new.png`}
        allowClickAway
        notification
        notificationSound
        chatMessage='Halo ada yang bisa saya bantu ?'
        placeholder='Halo saya ingin mengetahui info lebih lanjut tentang *Aplikasi vCloudProperty+*'
      />
      }
    </Router>
  );
}

export default App;
