import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios'
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import logoVcloud from '../assets/logo_new.png'
import './css/navbar.css'

const Navbar = () => {

    return (
        <div className='fixed-top'>
            <Grid container>
                <Grid item xs={12} className='f-navbar container-fluid'>
                    <div className='title-top-navbar'>
                        <p>
                            Direct Marketing & Whatsapp
                        </p>
                    </div>
                    <a href="tel:+622122637333">
                        <button className="btn my-2 my-sm-0 mx-1 button-top-navbar" type='button'>
                            <CallIcon className='img-fluid icon-top-navbar' />
                            02122637333
                        </button>
                    </a>
                    <a href="https://vcloudproperty.com">
                        <button className="btn my-2 my-sm-0 mx-1 button-top-navbar" type='button'>
                            <WhatsAppIcon className='img-fluid icon-top-navbar' />
                            6287776868717
                        </button>
                    </a>
                </Grid>
                <Grid item xs={12} className='s-navbar'>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className='container-fluid'>
                            <a className="navbar-brand" href="/">
                                <img src={logoVcloud} width="60" height="50" className="d-inline-block img-fluid" alt="" />
                                Virtual Properti Plus
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse flex-grow-1 justify-content-center" id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto">
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Solution
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href={`/solution/vDeveloper+`}>vDeveloper+</a>
                                        <a className="dropdown-item" href={`/solution/vEstate+`}>vEstate+</a>
                                        <a className="dropdown-item" href={`/solution/vTenancy+`}>vTenancy+</a>
                                        <a className="dropdown-item" href={`/solution/vAsset+`}>vAsset+</a>
                                        <a className="dropdown-item" href={`/solution/vProject+`}>vProject+</a>
                                        <a className="dropdown-item" href={`/solution/vAccounting+`}>vAccounting+</a>
                                        <a className="dropdown-item" href={`/solution/vPayment+`}>vPayment+</a>
                                        <a className="dropdown-item" href={`/solution/vPortal+`}>vPortal+</a>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog">Blog</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/partnership">Partnership</a>
                                </li>
                                <li className="nav-item">
                                    <span className='small-bar-price'>Promo</span>
                                    <a className="nav-link" href="/price">Pricing</a>
                                </li>
                                <li className="nav-item">
                                    <span className='small-bar-cloud'>New</span>
                                    <a className="nav-link" href="/private-cloud">Private Cloud</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Contact Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/about-us">About Us</a>
                                </li>
                                </ul>
                                <div className="form-inline my-2 my-lg-0">
                                <a href="https://login.vcloudproperty.com">
                                    <button className="btn btn-outline-success my-2 my-sm-0 mx-1 button-login">Login</button>
                                </a>
                                <a href="https://wiki.vcloudproperty.com">
                                    <button className="btn btn-outline-success my-2 my-sm-0 mx-1 button-wiki">Wiki</button>
                                </a>
                                <a href="http://support.vcloudproperty.com/support/login">
                                    <button className="btn btn-outline-success my-2 my-sm-0 mx-1 button-customer-portal">Customer Portal</button>
                                </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </Grid>
            </Grid>
        </div>
    )
}
export default Navbar;