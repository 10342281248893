import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from "@mui/material";

import './css/accordion.css'

const accordion = (props) => {
    const id = props && props.id
    const page = props && props.page
    const data = props && props.data

    return (
        <div className="container-fluid">
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <h2 className="accordion-header">{data.judul}</h2>
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <p className="accordion-item-detail">
                                {data.deskripsi}
                            </p>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
export default accordion;