import React, { Component, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import './Partnership.css'
import { variables } from '../../Variables';
import QuestionSection from '../../components/questionSection';

import headerBG from '../../assets/gambar_header/image.png'
import headerPartnership from '../../assets/gambar_header/partnership.jpg'


const Partnership = () => {

        // partnership
        const [selectedId, setSelectedId] = useState(0)
        const [partnershipGambar, setPartnershipGambar] = useState('partnership.jpg')
        const [partnershipText, setPartnershipText] = useState('<p>Seiring dengan perkembangan teknologi yang cepat dan pesat pada era digtal saat ini PT. Virtual Properti Plus terus melakukan inovasi agar dapat terus berkembang, dan sebagai salah satu bentuk inovasi kami memiliki program kemitraan yang memungkinkan kami untuk berkolaborasi dengan para mitra kami untuk mendistribusikan produk vCloudProperty+.</p><p>Untuk informasi lebih lanjut terkait dengan program kemitraan ini silahkan untuk menghubungi kami dengan mengisi form di bawah ini.</p>')
    
        useEffect(() => {
            // fetch(variables.API_URL+'partnership')
            // .then(response=>response.json())
            // .then(data=> {
            //     console.log('data', data)
            //     setSelectedId(data[0].id)
            //     setPartnershipGambar(data[0].gambar)
            //     setPartnershipText(data[0].text_partnership)
                
            //     // setHeadTable
            // })
            
        }, [])

    return (
        <div className='partnership'>
            <div className='partnership-header'>
                <div className='header-bg-partner container-fluid' style={{ backgroundImage: `url(${headerBG})` }}>
                    <h1 className='header-title'>Partnership</h1>
                </div>
            </div>
            <div className='partnership-content'>
                <div className='container container-fluid'>
                    <div className='partnership-content-desc'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={5}>
                                {/* <img className='img-fluid' width={420} src={variables.PHOTO_URL+'Partnership/'+partnershipGambar} /> */}
                                <img className='img-fluid' width={420} src={'/gambar_header/' + partnershipGambar} />
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <div className='partnership-content-desc-text'>
                                    <div dangerouslySetInnerHTML={{__html: partnershipText}} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <div className='partnership-content-contact'>
                <QuestionSection />
                <div className='sp-curved-1'><div className='sp-curved-2'></div></div>
            </div>
        </div>
    )
}
export default Partnership;