import React, { Component, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

import logoVcloud from '../assets/logo_new.png'
import footerTelp from '../assets/img/tlpn.png'
import footerWA from '../assets/img/whatsapp.svg'
import footerBCA from '../assets/img/bca.png'
import footerMandiri from '../assets/img/mandiri.png'
import footerPermata from '../assets/img/permata.png'
import footerBNI from '../assets/img/bni.png'
import footerATMBersama from '../assets/img/atmbersama.png'
import footerVisa from '../assets/img/visa.png'
import footerAlto from '../assets/img/alto.png'
import footerMasterCard from '../assets/img/mastercard.png'
import footerBRI from '../assets/img/bri.png'
import footerCIMB from '../assets/img/cimb.png'
import footerMandiriDebit from '../assets/img/mandiridebit.png'
import footerBCAKlikPay from '../assets/img/bcaklikpay.png'
import footerFB from '../assets/img/fb.png'
import footerIG from '../assets/img/ig.png'
import footerYT from '../assets/img/youtube.png'
import footerTWT from '../assets/img/twitter.png'
import footerAppstore from '../assets/img/appstore.png'
import footerPlaystore from '../assets/img/playstore.png'

import './css/footer.css'

const Footer = () => {

    return (
        <div className='footer'>
            <footer className='App-footer'>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} className='footer-contact'>
                    <div className='footer-contact-content'>
                        <img src={logoVcloud} width="60" height="50" className="d-inline-block align-top img-fluid" alt="" />
                        <b> Virtual Properti Plus</b>
                    </div>
                    <div className='footer-contact-content'>
                        <img src={footerTelp} width="18" height="18" className="d-inline-block align-top img-fluid" alt="" />
                        <b> +0213106663</b>
                    </div>
                    <div className='footer-contact-content'>
                        <img src={footerWA} width="18" height="18" className="d-inline-block align-top img-fluid" alt="" />
                        <b> +6287776868717</b>
                    </div>
                    <div className='footer-contact-content'>
                        <b style={{display: "block"}}>Payment Partners</b>

                    </div>
                    <div className='footer-contact-content'>
                        <Grid container spacing={1} className='footer-contact-grid'>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerBCA} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerMandiri} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerPermata} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerBNI} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerATMBersama} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerVisa} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerAlto} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerMasterCard} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerBRI} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerCIMB} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerMandiriDebit} />
                        </Grid>
                        <Grid item xs={3}>
                            <img className='img-fluid' src={footerBCAKlikPay} />
                        </Grid>
                        </Grid>
                    </div>
                    </Grid>
                    <Grid item xs={12} lg={3} className='footer-business-solution'>
                    <h2 className='footer-business-solution-title'>Business Solution</h2>
                    <Grid container spacing={1} className='footer-business-solution-grid-container'>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vDeveloper+">vDeveloper+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vEstate+">vEstate+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vTenancy+">vTenancy+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vAsset+">vAsset+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vProject+">vProject+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vAccounting+">vAccounting+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vPayment+">vPayment+</a>
                        </Grid>
                        <Grid item xs={6} className='footer-business-solution-grid-item'>
                        <a href="/solution/vPortal+">vPortal+</a>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} lg={2} className='footer-other'>
                    <h2 className='footer-other-title'>Other</h2>
                    <Grid container spacing={1} className='footer-other-grid-container'>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="/blog">BLOG</a>
                        </Grid>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="/partnership">PARTNERSHIP</a>
                        </Grid>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="/price">PRICE</a>
                        </Grid>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="/privatecloud">PRIVATE CLOUD</a>
                        </Grid>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="#">CONTACT US</a>
                        </Grid>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="/aboutus">ABOUT US</a>
                        </Grid>
                        <Grid item xs={12} className='footer-other-grid-item'>
                        <a href="#">API REFERENCE</a>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3} className='footer-subscribe'>
                    <h2 className="footer-subscribe-title">
                        SUBSCRIBE FOR UPDATES
                    </h2>
                    <div className="footer-subscribe-text-field">
                        <TextField className='text-subscribe-footer' id="filled-basic" label="Recipient's Email" variant="filled" color='info' />
                        <Button style={{maxWidth: '56px', maxHeight: '56px', minWidth: '56px', minHeight: '56px'}} sx={{ height: '75px' }} className='button-subscribe-footer' variant="contained" endIcon={<SendIcon />} color='info'></Button>
                    </div>
                    <div className="footer-subscribe-socmed">
                        <a href='https://www.facebook.com/vcloudproperty'>
                            {/* <img className='img-fluid' src={footerFB} /> */}
                            {/* <FontAwesomeIcon icon="fa-brands fa-facebook-f" /> */}
                            <FontAwesomeIcon icon={faFacebookF} size='xl' style={{color: "#000000", marginRight: '18    px'}} />
                        </a>
                        <a href='https://www.instagram.com/vcloudproperty/'>
                            <img className='img-fluid' src={footerIG} />
                            
                        </a>
                        <a href='https://www.youtube.com/channel/UCuJfPIIQRyn24UOEkrhGXsw'>
                            <img className='img-fluid' src={footerYT} />
                            
                        </a>
                        <a href='https://twitter.com/vcloudproperty'>
                            <img className='img-fluid' src={footerTWT} />
                            
                        </a>
                    </div>
                    <div className="footer-subscribe-app-download">
                        <a href="#">
                        <img className='img-fluid' src={footerAppstore} />
                        </a>
                        <a href="#">
                        <img className='img-fluid' src={footerPlaystore} />
                        </a>
                    </div>
                    </Grid>
                </Grid>
            </footer>
        </div>
    )
}
export default Footer;