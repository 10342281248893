import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import './css/propertySolution.css'

import SolutionIcon1 from '../assets/img/icon1.png'
import SolutionIcon2 from '../assets/img/icon2.png'
import SolutionIcon3 from '../assets/img/icon3.png'
import SolutionIcon4 from '../assets/img/icon4.png'
import SolutionIcon5 from '../assets/img/icon5.png'

const propertySolution = () => {

    return (
        <div className='property-solution container container-fluid'>
            <h1 className='title-property-solution underline'>End to End Property Solution</h1>
            <Grid container spacing={2} className='grid-container'>
                <Grid item xs={12} lg={6} className='grid-item'>
                    <Grid container spacing={1} className='grid-children-container'>
                        <Grid item xs={1} className='grid-children-item-img'>
                            <img className='img-fluid' src={SolutionIcon1}/>
                        </Grid>
                        <Grid item xs={11} className='grid-children-item-img'>
                            <Grid container spacing={1} className='grid-children-container'>
                                <Grid item xs={12} className='grid-children-item-title h2 underline'>
                                    vCloudProperty+ Solution
                                </Grid>
                                <Grid item xs={12} className='grid-children-item-content'>
                                    We provide complete and integrated property business solution for any property type including Landed Housing, Shopping Mall, Office Tower, Estate Management, Industrial Estate, and other property type.
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} className='grid-item'>
                    <Grid container spacing={1} className='grid-children-container'>
                        <Grid item xs={1} className='grid-children-item-img'>
                            <img className='img-fluid' src={SolutionIcon2}/>
                        </Grid>
                        <Grid item xs={11} className='grid-children-item-img'>
                            <Grid container spacing={1} className='grid-children-container'>
                                <Grid item xs={12} className='grid-children-item-title h2 underline'>
                                    Cloud Based Technology
                                </Grid>
                                <Grid item xs={12} className='grid-children-item-content'>
                                    Our Solution is a Cloud Based Solution that can be access anywhere on any device.
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} className='grid-item'>
                    <Grid container spacing={1} className='grid-children-container'>
                        <Grid item xs={1} className='grid-children-item-img'>
                            <img className='img-fluid' src={SolutionIcon4}/>
                        </Grid>
                        <Grid item xs={11} className='grid-children-item-img'>
                            <Grid container spacing={1} className='grid-children-container'>
                                <Grid item xs={12} className='grid-children-item-title h2 underline'>
                                    Affordable Pricing
                                </Grid>
                                <Grid item xs={12} className='grid-children-item-content'>
                                    We offer a subscription based pricing scheme for vCloudProperty + Solution.
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} className='grid-item'>
                    <Grid container spacing={1} className='grid-children-container'>
                        <Grid item xs={1} className='grid-children-item-img'>
                            <img className='img-fluid' src={SolutionIcon3}/>
                        </Grid>
                        <Grid item xs={11} className='grid-children-item-img'>
                            <Grid container spacing={1} className='grid-children-container'>
                                <Grid item xs={12} className='grid-children-item-title h2 underline'>
                                    Unlimited User License
                                </Grid>
                                <Grid item xs={12} className='grid-children-item-content'>
                                    vCloudProperty+ are usable by as many users as posisible.
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} lg={3} className='grid-item' />
                <Grid item xs={12} lg={6} className='grid-item'>
                    <Grid container spacing={1} className='grid-children-container'>
                        <Grid item xs={1} className='grid-children-item-img'>
                            <img className='img-fluid' src={SolutionIcon5}/>
                        </Grid>
                        <Grid item xs={11} className='grid-children-item-img'>
                            <Grid container spacing={1} className='grid-children-container'>
                                <Grid item xs={12} className='grid-children-item-title h2 underline'>
                                    Support
                                </Grid>
                                <Grid item xs={12} className='grid-children-item-content'>
                                    Our teams will always be available to assist our client & ensure that vCloudProperty+ are working well.
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} lg={3} className='grid-item' />
            </Grid>
        </div>
    )
}
export default propertySolution;