import React, { Component, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import axios from 'axios';

import './css/businessSolution.css'
import { variables } from '../Variables';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import BusineessSolutionVDeveloper from '../assets/gambar_product/vdeveloper.jpg'
import BusineessSolutionVEstate from '../assets/gambar_product/vestate.jpg'
import BusineessSolutionVTenancy from '../assets/gambar_product/vtenancy.jpg'
import BusineessSolutionVAsset from '../assets/gambar_product/vasset.jpg'
import BusineessSolutionVProject from '../assets/gambar_product/vproject.jpg'
import BusineessSolutionVAccounting from '../assets/gambar_product/vaccounting.jpg'
import BusineessSolutionVPayment from '../assets/gambar_product/vpayment.jpg'
import BusineessSolutionVPortal from '../assets/gambar_product/vportal.jpg'

import BusineessSolutionVDeveloperBar from '../assets/img/designimg/vdeveloper.png'
import BusineessSolutionVEstateBar from '../assets/img/designimg/vestate.png'
import BusineessSolutionVTenancyBar from '../assets/img/designimg/vtenancy.png'
import BusineessSolutionVAssetBar from '../assets/img/designimg/vasset.png'
import BusineessSolutionVProjectBar from '../assets/img/designimg/vproject.png'
import BusineessSolutionVAccountingBar from '../assets/img/designimg/vaccounting.png'
import BusineessSolutionVPaymentBar from '../assets/img/designimg/vpayment.png'
import BusineessSolutionVPortalBar from '../assets/img/designimg/vportal.png'

const businessSolution = (props) => {
    const productData = props && props.productData && props.productData

    const handleClickOpen = () => {
        props.setOpen(true);
    };

    const handleClose = () => {
        props.setOpen(false);
    };
    return (
        <div className='business-solution container container-fluid'>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <div className='business-solution-sidebar'>
                        <h2 className='business-solution-sidebar-title'>
                            Our Business Solutions
                        </h2>
                        <p className='business-solution-sidebar-desc'>
                            The most comprehensive end to end solution for property industry
                        </p>
                        <a href={`${window.location.origin}/assets/Solution_Maps.pdf`} target='_blank'>
                            <Button variant="outlined" className='business-solution-sidebar-button'>Solution Roadmap</Button>
                        </a>
                        <Button variant="outlined" className='business-solution-sidebar-button' onClick={handleClickOpen}>System Flow</Button>
                    </div>
                </Grid>
                <Grid item xs={12} lg={9}>
                    {/* Live Data */}
                    <Grid container spacing={2}>
                        {productData && productData.map((product)=>{
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} className='grid-item-business-sol'>
                                    <div className='grid-item-business'>
                                        <a href={`/solution/${product.nama}`}>
                                            {/* <img className='img-design img-fluid' src={variables.PHOTO_URL+'product/'+product.gambar} /> */}
                                            <img className='img-design img-fluid' src={'/gambar_product/'+product.gambar} />
                                            <img className='img-bar design-overlay img-fluid' src={`../assets/img/designimg/${product.logo}.png`} />
                                        </a>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullScreen={props.fullScreen}
                open={props.open}
                onClose={handleClose}
                maxWidth={'360px'}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <img className='img-dialog img-fluid' src={`${window.location.origin}/assets/systemflow.jpg`} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default businessSolution;