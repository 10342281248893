import React from "react";
import './P404.css'

const P404 = () => {

    return (
        <div className="error-page">
            <h1>404 NOT FOUND</h1>
        </div>
    )
}
export default P404;