import React from "react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import './css/strategicPartner.css'

import TDSPartner from '../assets/img/parther/tds.png'
import AGSPartner from '../assets/img/parther/ags.png'
import GosyenPartner from '../assets/img/parther/gosyen.png'
import XenditPartner from '../assets/img/parther/xendit.png'
import YabesPartner from '../assets/img/parther/yabes.png'

const strategicPartner = () => {

    return (
        <div className="strategic-partner container container-fluid">
            <h2 className="strategic-partner-title">Strategic Partner</h2>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3} />
                <Grid item xs={6} lg={2}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            sx={{ padding: '18px', height: 130, objectFit: 'contain' }}
                            image={TDSPartner}
                            alt="green iguana"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                {/* <Grid item xs={6} lg={2}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            sx={{ padding: '18px', height: 130, objectFit: 'contain' }}
                            image={YabesPartner}
                            alt="green iguana"
                            />
                        </CardActionArea>
                    </Card>
                </Grid> */}
                <Grid item xs={6} lg={2}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            sx={{ padding: '18px', height: 130, objectFit: 'contain' }}
                            image={GosyenPartner}
                            alt="green iguana"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Card sx={{ maxWidth: 345 }} >
                        <CardActionArea>
                            {/* <img src={XenditPartner} style={{height: 130, width: 147, objectFit: 'contain'}}/> */}
                            <CardMedia
                            component="img"
                            sx={{ padding: '18px', height: 130, objectFit: 'contain' }}
                            image={XenditPartner}
                            alt="green iguana"
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3} />
            </Grid>
        </div>
    )
}
export default strategicPartner;