import React, { Component, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BallotIcon from '@mui/icons-material/Ballot';
import StorageIcon from '@mui/icons-material/Storage';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import './Price.css'

const Price = () => {
    const [togglePrice, setTogglePrice] = useState('basic');

    const handleChange = (event, newTogglePrice) => {
        setTogglePrice(newTogglePrice);
    };

    function createData(name, basic, premium) {
    return { name, basic, premium };
    }
      
    const salesAdministrationData = [
    createData('Promotion & Gimmick', true, true),
    createData('Unit Master', true, true),
    createData('Reservation', true, true),
    createData('Booking Entry', true, true),
    createData('Legal Document Administration', true, true),
    createData('Change Payment Plan', true, true),
    createData('Cancellation', true, true),
    createData('Change Customer', true, true),
    createData('KPR / KPA Management', true, true),
    createData('Sales Commision Management', false, true),
    ];
    const salesAdministrationFormData = [
        createData('Surat Pesanan', true, true),
        createData('Payment Schedule', true, true),
        createData('Memo Pembatalan', true, true),
        createData('PPJB', true, true),
        createData('BAST Unit', true, true),
    ];
    const salesAdministrationReport = [
        createData('Sales Report By Period', true, true),
        createData('Sales Report By Salesman', true, true),
        createData('Sales Report By Property Type', true, true),
        createData('Sales Report By Cluster', true, true),
        createData('Sales Report By Project', true, true),
        createData('Unit Status Report', true, true),
        createData('Customer Master Report', true, true),
        createData('KPR / KPA Administration', true, true),
        createData('Sales Commission Report', false, true),
        createData('Sales vs Collection Report', false, true),
    ];

    const accountReceivableData = [
        createData('Official Receipt', true, true),
        createData('A/R Tax Invoice (E-Faktur)', true, true),
        createData('Credit Note / Adjustment', true, true),
        createData('Invoice Entry', true, true),
        createData('Penalty Management', false, true),
        createData('Reminder Generation', false, true),
    ];
    const accountReceivableReport = [
        createData('Kartu Piutang', true, true),
        createData('A/R Aging', true, true),
        createData('Official Receipt', true, true),
        createData('Kwitansi', true, true),
        createData('Tanda Terima Sementara', true, true),
        createData('A/R Aging', true, true),
        createData('Surat Peringatan 1,2 & 3', false, true),
        createData('KPR Collection Report', true, true),
        createData('Penalty Listing', false, true),
        createData('Receipt Voucher', true, true),
        createData('Cash in Forecast Report', false, true),
    ];

    const cashbookData = [
        createData('Bank Master File', true, true),
        createData('Cash/Bank Entry', true, true),
    ];
    const cashbookReport = [
        createData('Bank Statement Report', true, true),
        createData('Receipt Voucher', true, true),
        createData('Payment Voucher', true, true),
        createData('Payment Request', false, true),
    ];

    const generalLedgerData = [
        createData('Payment Request', true, true),
        createData('Chart of Account', true, true),
        createData('Journal Entry', true, true),
        createData('Recurring Journal Entry', false, true),
        createData('Budget Entry', true, true),
        createData('Audit Journal Entry', false, true),
        createData('Cashflow', false, true),
        createData('Bank Reconciliation', false, true),
        createData('Month & Year Closing', true, true),
    ];
    const generalLedgerReport = [
        createData('Balance Sheet', true, true),
        createData('Profit Loss', true, true),
        createData('Cashflow', false, true),
        createData('Journal Listing', true, true),
        createData('Trial Balance', false, true),
        createData('Transaction by COA', false, true),
        createData('Budget Listing Report', false, true),
    ];

    const optionalModulesData = [
        createData('Land Acquisition', null, null),
        createData('Projet Ledger', null, null),
        createData('SPK / Sub Contractor Management', null, null),
        createData('Purchase Order', null, null),
        createData('Inventory Control', null, null),
        createData('Ownership / Estate Management', null, null),
        createData('Tenancy Management', null, null),
        createData('Meter Utility Management', null, null),
        createData('Ownership Billing System', null, null),
        createData('Tenancy Billing System', null, null),
        createData('Account Payable', null, null),
        createData('Vendor Management / Payable', null, null),
        createData('Fixed Asset Management', null, null),
        createData('Preventive Maintenance', null, null),
        createData('Online Booking', null, null),
        createData('NUP', null, null),
        createData('Marketing / Prospecting', null, null),
        createData('Payment Automation', null, null),
        createData('H2H Virtual Account', null, null),
        createData('Customer Service', null, null),
        createData('Overtime', null, null),
        createData('Workflow Approval', null, null),
        createData('SMS Gateway / Blasting', null, null),
        createData('Financilal Consolidation', null, null),
        createData('Customer Portal', null, null),
        createData('Agent / Seles Portal', null, null),
        createData('Tenant Web Portal', null, null),
    ];

    function renderTableWithoutTitle(data) {
        return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} align="right">Basic</TableCell>
                        <TableCell align="right">Premium</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.name}
                        </TableCell>
                        <TableCell align="right" sx={{ width: 180 }}>{(row.basic === true) ? <CheckIcon /> : <CloseIcon />}</TableCell>
                        <TableCell align="right" sx={{ width: 180 }}>{(row.premium === true) ? <CheckIcon /> : <CloseIcon />}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }
    function renderTableWithTitle(data, title) {
        return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}><b>{title}</b></TableCell><TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                {data.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right" sx={{ width: 180 }}>{(row.basic === true) ? <CheckIcon /> : (row.basic === false) ? <CloseIcon /> : ''}</TableCell>
                    <TableCell align="right" sx={{ width: 180 }}>{(row.premium === true) ? <CheckIcon /> : (row.premium === false) ? <CloseIcon /> : ''}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        )
    }

    function renderBasicPrice () {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={2}/>
                    <Grid item xs={12} lg={4}>
                        <Card sx={{ minWidth: 275, backgroundColor: '#f8f8f8', padding: 0}}>
                            <CardContent>
                                <h1 className='price-card-title'>ANNUALY</h1>
                                <div className='price-card-price'>
                                    <p className='price-card-price-1'>IDR 8.500.000 /Year</p>
                                    <p className='price-card-price-2'>(Annual Commitment)</p>
                                </div>
                                <div className='price-card-detail'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Unlimited User License</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Jumlah Unit Maksimum 500 Unit</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Minimal Subscription 1 Tahun</Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                            <CardActions className='price-card-action'>
                                <Button size="small">Buy Now</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                    <Card sx={{ minWidth: 275, backgroundColor: '#f8f8f8'}}>
                            <CardContent>
                                <h1 className='price-card-title'>MONTHLY</h1>
                                <div className='price-card-price'>
                                    <p className='price-card-price-1'>IDR 800.000 /Month</p>
                                    <p className='price-card-price-2'>(Annual Commitment)</p>
                                </div>
                                <div className='price-card-detail'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Unlimited User License</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Jumlah Unit Maksimum 500 Unit</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Minimal Subscription 1 Tahun</Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                            <CardActions className='price-card-action'>
                                <Button size="small">Buy Now</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2}/>
                </Grid>
            </div>
        )
    }
    function renderPremiumPrice () {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={2} />
                    <Grid item xs={12} lg={4}>
                        <Card sx={{ minWidth: 275, backgroundColor: '#f8f8f8'}}>
                            <CardContent className='card-price-content'>
                                <h1 className='price-card-title'>STANDARD</h1>
                                <div className='price-card-price'>
                                    <p className='price-card-price-1'>IDR 1.250.000 /Month</p>
                                    <p className='price-card-price-2'>(Annual Commitment)</p>
                                </div>
                                <div className='price-card-detail'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Unlimited User License</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Jumlah Unit Maksimum 750 Unit</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Minimal Subscription 1 Tahun</Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                            <CardActions className='price-card-action'>
                                <Button size="small">Buy Now</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                    <Card sx={{ minWidth: 275, backgroundColor: '#f8f8f8'}}>
                            <CardContent>
                                <h1 className='price-card-title'>PREMIUM</h1>
                                <div className='price-card-price'>
                                    <p className='price-card-price-1'>IDR 1.500.000 /Month</p>
                                    <p className='price-card-price-2'>(Annual Commitment)</p>
                                </div>
                                <div className='price-card-detail'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Unlimited User License</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Jumlah Unit Maksimum 1000 Unit</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}><CheckCircleOutlineIcon sx={{color: '#2bc6a6'}} /></Grid>
                                        <Grid item xs={10}>Minimal Subscription 1 Tahun</Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                            <CardActions className='price-card-action'>
                                <Button size="small">Buy Now</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={2} />
                </Grid>
            </div>
        )
    }
    function renderFeature () {
        return (
            <div className='price-features'>
                <h1 className='price-features-title'>FEATURES</h1>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <HomeIcon />
                        <h1>Unit Availability</h1>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <LocalAtmIcon />
                        <h1>Sales</h1>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <CreditCardIcon />
                        <h1>KPR / KPA</h1>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <AssessmentIcon />
                        <h1>Project</h1>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <BallotIcon />
                        <h1>Accounting</h1>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <StorageIcon />
                        <h1>Collection</h1>
                    </Grid>
                </Grid>
            </div>
        )
    }
    
    function salesAdministrationPlan(data1) {
        return (
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='price-accordion'
                >
                <h2 className="accordion-header">Sales Administration</h2>
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                    <div className='desc-price-plans'>
                        <TableContainer component={Paper}>
                            {renderTableWithoutTitle(salesAdministrationData)}
                            {renderTableWithTitle(salesAdministrationFormData, 'Sales Administration Form')}
                            {renderTableWithTitle(salesAdministrationReport, 'Sales Report')}
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
    function accountReceivablePlan() {
        return (
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='price-accordion'
                >
                <h2 className="accordion-header">Account Receivable</h2>
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                    <div className='desc-price-plans'>
                        <TableContainer component={Paper}>
                            {renderTableWithoutTitle(accountReceivableData)}
                            {renderTableWithTitle(accountReceivableReport, 'Report')}
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
    function cashbookPlan() {
        return (
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='price-accordion'
                >
                <h2 className="accordion-header">Cashbook</h2>
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                    <div className='desc-price-plans'>
                        <TableContainer component={Paper}>
                            {renderTableWithoutTitle(cashbookData)}
                            {renderTableWithTitle(cashbookReport, 'Report')}
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
    function generalLedgerPlan() {
        return (
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='price-accordion'
                >
                <h2 className="accordion-header">General Ledger</h2>
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                    <div className='desc-price-plans'>
                        <TableContainer component={Paper}>
                            {renderTableWithoutTitle(generalLedgerData)}
                            {renderTableWithTitle(generalLedgerReport, 'Report')}
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }
    function optionalModulesPlan() {
        return (
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='price-accordion'
                >
                <h2 className="accordion-header">Optional Modules</h2>
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                    <div className='desc-price-plans'>
                        <TableContainer component={Paper}>
                            {renderTableWithTitle(optionalModulesData, '')}
                        </TableContainer>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    function renderComparePlans () {
        return (
            <div className='price-plans'>
                <h1 className='price-plans-title'>COMPARE PLANS</h1>
                {salesAdministrationPlan()}
                {accountReceivablePlan()}
                {cashbookPlan()}
                {generalLedgerPlan()}
                {optionalModulesPlan()}
            </div>
        )
    }

    return (
        <div className='price'>
            <div className='container container-fluid'>
                <div className='price-content'>
                    <div className='price-pricing'>
                        <h1 className='price-pricing-title'>PRICING</h1>
                        <div className='price-pricing-toggle'>
                            <ToggleButtonGroup
                                color="primary"
                                value={togglePrice}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="basic">Basic</ToggleButton>
                                <ToggleButton value="premium">Premium</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {(togglePrice && togglePrice === 'premium') ? renderPremiumPrice() : renderBasicPrice()}
                    </div>
                    {(togglePrice && togglePrice === 'basic') ? renderFeature() : ''}
                    {(togglePrice && togglePrice === 'premium') ? renderComparePlans() : ''}
                    <div className='price-faq'>
                        <h1 className='price-faq-title'>FREQUENTLY ASKED QUESTIONS</h1>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='price-accordion'
                            >
                            <h2 className="accordion-header">What is vCloudProperty+?</h2>
                            </AccordionSummary>
                            <AccordionDetails className="accordion-content">
                                <div className='desc-price-faq'>
                                    <p>
                                        vCloudProperty+ adalah suatu solusi berbasis cloud yang komprehensif dan terintegrasi yang dikembangkan oleh PT. Virtual Properti Plus, yang ditujukan untuk berbagai jenis property baik itu perumahan, perkantoran, shopping mall, industrial estate, mixed used development, dll. Solusi yang ditawakan oleh vCloudProperty+ mencakup seluruh proses bisnis yang berhubungan dengan properti, termasuk property management, real estate management, building management, procurement management, financial accounting management. Modul-modul dan fitur yang ada pada vCloudProperty+ dikembangkan berdasarkan standard bisnis terbaik dalam industri properti.
                                    </p>
                                    <p>
                                        Berikut ini adalah beberapa keunggulan yang akan anda dapatkan dengan menggunakan vCloudProperty+ :
                                    </p>
                                    <ol>
                                        <li>vCloudProperty+ sudah dirancang sesuai dengan standar bisnis terbaik dan dapat dikonfigurasi berdasarkan kebutuhan klien, selain itu vCloudProperty+ juga sudah dirancang sesuai dengan regulasi / peraturan pemerintah Indonesia.</li>
                                        <li>vCloudProperty+ dapat digunakan tanpa adanya batasan pengguna / user dalam suatu perusahaan.</li>
                                        <li>Layanan dukungan dengan tim support dedicated untuk para klien.</li>
                                        <li>Dengan menggunakan teknologi berbasis cloud maka vCloudProperty+ dapat diakses melalui berbagai macam perangkat (notebook, smartphone, tablet, dll).</li>
                                    </ol>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Price;