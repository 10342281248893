import React, { Component, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import './PrivateCloud.css'
import { variables } from '../../Variables';
import Accordions from '../../components/accordion'

const PrivateCloud = () => {
    const [fetchFaqInformation, setFetchFaqInformation] = useState([])
    const [fetchFaqInformationDummy, setFetchFaqInformationDummy] = useState([
        {
            "id": 1,
            "judul": "Apa perbedaan antara VP+ Public Cloud dengan VP+ Private Cloud?",
            "deskripsi": "Layanan VP+ Public Cloud ditujukan untuk digunakan secara bersama-sama dengan perusahaan lain, sedangkan layanan VP+ Private Cloud merupakan layanan eksklusif yang didedikasikan untuk pengguna layanan VP+ Private Cloud saja dan berjalan pada infrastruktur milik Anda sendiri.",
            "created_at": "2021-09-01T07:55:06",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 2,
            "judul": "Di luar biaya server untuk VP+ Private Cloud, berarti saya harus membayar biaya berlangganan?",
            "deskripsi": "Benar, untuk skema biaya berlangganan dan biaya tambahan pengguna dapat Anda lihat pada halaman ini.",
            "created_at": "2021-09-01T07:55:57",
            "updated_at": null,
            "deleted_at": null
        },
        {
            "id": 3,
            "judul": "Berapa minimum spesifikasi untuk server VP+ Private Cloud?",
            "deskripsi": "Spesifikasi minimum menggunakan Internet bandwidth 4Mbps (upstream dan downstream), Octa Core Intel Xeon /AMD Epyc Processor demham memory 8GB Memory, 512GB SSD storage dan Windows OS (64 bit) atau Linux Ubuntu (64 bit).",
            "created_at": "2021-09-01T07:56:20",
            "updated_at": null,
            "deleted_at": null
        }
    ])
    const [objectKeys, setObjectKeys] = useState([])

    useEffect(() => {
        // fetch(variables.API_URL+'faq')
        // .then(response=>response.json())
        // .then(data=> {
        //     console.log('dataasdasda', data)
        //     setFetchFaqInformation(data)
        //     setObjectKeys(Object.keys(data && data[0]))
        //     // setHeadTable
        // })
        
    }, [])

    const privateCloudFAQ1 = {
        'title' : 'Apa perbedaan antara VP+ Public Cloud dengan VP+ Private Cloud?',
        'page' : 'private-cloud',
        'data' : [
            {
                'id' : '1',
                'desc' : 'Layanan VP+ Public Cloud ditujukan untuk digunakan secara bersama-sama dengan perusahaan lain, sedangkan layanan VP+ Private Cloud merupakan layanan eksklusif yang didedikasikan untuk pengguna layanan VP+ Private Cloud saja dan berjalan pada infrastruktur milik Anda sendiri.'
            }
        ]
    }
    const privateCloudFAQ2 = {
        'title' : 'Di luar biaya server untuk VP+ Private Cloud, berarti saya harus membayar biaya berlangganan?',
        'page' : 'private-cloud',
        'data' : [
            {
                'id' : '1',
                'desc' : 'Benar, untuk skema biaya berlangganan dan biaya tambahan pengguna dapat Anda lihat pada halaman ini.'
            }
        ]
    }
    const privateCloudFAQ3 = {
        'title' : 'Berapa minimum spesifikasi untuk server VP+ Private Cloud?',
        'page' : 'private-cloud',
        'data' : [
            {
                'id' : '1',
                'desc' : 'Spesifikasi minimum menggunakan Internet bandwidth 4Mbps (upstream dan downstream), Octa Core Intel Xeon /AMD Epyc Processor demham memory 8GB Memory, 512GB SSD storage dan Windows OS (64 bit) atau Linux Ubuntu (64 bit).'
            }
        ]
    }

    return (
        <div className='private-cloud'>
            <div className='container container-fluid'>
                <div className='private-cloud-content'>
                    <div className='private-cloud-card'>
                        <h1 className='private-cloud-card-title'>Private Cloud</h1>
                        <div className='private-cloud-card-content'>
                            <Card sx={{ maxWidth: 400, backgroundColor: '#f3f3f3' }}>
                                <CardContent>
                                    <div className='private-cloud-card-detail'>
                                        <div className='private-cloud-card-content-title'>
                                            Layanan VP+ Private Cloud
                                        </div>
                                        <div className='private-cloud-card-content-content'>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2} />
                                                <Grid item xs={8}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={2}>
                                                            <p className='card-price-1'>
                                                                RP
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <p className='card-price-2'>
                                                                4
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <p className='card-price-3'>Juta</p>
                                                        </Grid>
                                                        <Grid item xs={5} className='card-price-4'>
                                                            <p>Per bulan</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2} />
                                            </Grid>
                                        </div>
                                        <div className='private-cloud-card-content-desc'>
                                            (Annual Commitment)
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    <div className='private-cloud-faq'>
                        <h1 className='private-cloud-faq-title'>VP+ Private Cloud FAQ</h1>
                        {fetchFaqInformationDummy && fetchFaqInformationDummy.map((faq)=>{
                            return <Accordions data={faq} page='faq' />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrivateCloud;